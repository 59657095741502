"use client";
import dynamic from "next/dynamic";
import { DatadogInit, UserContextInit } from "../_components/datadog/rum";
import { ThemeProvider } from "@optimization/widgets/ThemeProvider";
import { HeaperInitScript } from "../_components/heaper/HeaperInitScript";
import { HeaperInit } from "../_components/heaper/heaper";
import ApolloProvider from "./apollo-provider";
import Script from "next/script";

const AuthProvider = dynamic(() => import("./auth-provider"), { ssr: false });
const FeatureFlagProvider = dynamic(() => import("./feature-flag-provider"), {
  ssr: false,
});
const ReactDevTools = () => {
  return (
    <Script id="react-devtools">
      {`if (window.parent !== window) {
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;
        }`}
    </Script>
  );
};

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <HeaperInitScript />
      <body>
        <ReactDevTools />
        <DatadogInit />
        <ThemeProvider>
          <AuthProvider>
            <ApolloProvider>
              <FeatureFlagProvider>
                <>
                  <HeaperInit />
                  <UserContextInit />
                  {children}
                </>
              </FeatureFlagProvider>
            </ApolloProvider>
          </AuthProvider>
        </ThemeProvider>
      </body>
    </html>
  );
}
